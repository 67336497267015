import React from "react"
import {
  Navigation,
  Blog,
  Footer,
  Hero,
  Breadcrumb,
} from "../../components/Marketing"
import { Seo } from "../../components/Seo/Seo"
import { graphql, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"

export default ({ data }) => {
  const stats = data.allHaikusTsv.group
  const redirections = data.allRedirectionsTsv.edges
  const themes = data.allThemesTsv.edges
    .map(theme => ({
      ...theme.node,
      url:
        redirections.find(({ node }) => node.id === theme.node.id)?.node?.to ||
        `/recueils/thematiques/${theme.node.id}/`,
      count: stats.find(stat => theme.node.id === stat.theme).count,
    }))
    .sort((before, after) => {
      return after.count - before.count
    })

  const illustration = getImage(data.illustration)

  const breadcrumbs = [
    { label: "Recueils de haïkus", url: "/recueils/" },
    { label: "Par thématiques", url: "/recueils/thematiques/" },
  ]

  return (
    <>
      <Seo
        title={`Exemples de haïkus par saisons thématiques`}
        description={`La page des recueils d'exemple de haïkus par thématiques regroupe l'ensemble des haïkus des anciens auteurs, classés par saison et thématiques comme l'amour, la guerre...`}
        breadcrumbs={breadcrumbs}
        image={illustration.images.fallback.src}
      />
      <Navigation />
      <Hero
        illustration={illustration}
        theme={{
          as: "div",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          value: (
            <>
              Recueil d'exemples de{" "}
              <span className="text-green-600">haïkus</span> par saisons et par
              thématiques
            </>
          ),
        }}
        description={{
          value: (
            <>
              Les haïkistes présents dans notre page{" "}
              <Link
                to="/recueils/auteurs/"
                className="font-medium text-green-600 cursor-pointer hover:underline"
              >
                Recueils de haïkus par auteurs
              </Link>{" "}
              ont écrit des haïkus dans pleins de thèmes différents. Par exemple{" "}
              <Link
                to="/poete-japonais/matsuo-basho/"
                className="font-medium text-green-600 cursor-pointer hover:underline"
              >
                Matsuo Bashõ
              </Link>{" "}
              a écrit des haïkus autour de l'amour, la guerre et évidement les
              saisons et la nature.
              <br />
              Grâce à cette page, vous pouvez retrouver l'intégralité des haïkus
              présents dans notre base de données, classés par thématiques.
            </>
          ),
        }}
      />
      <Blog
        title={{
          as: "h2",
          value: (
            <>
              Thématiques d'exemples <br />
              classés par le nombre de{" "}
              <span className="text-green-600">haïkus</span>
            </>
          ),
        }}
        description={
          <>
            Cette liste de thématiques n'est pas exhaustives et sera alimentée
            régulièrement.
          </>
        }
        articles={themes.map(({ label, id, url }) => ({
          id,
          category: "Recueil",
          url,
          title: label,
          description: `Le recueil des haïkus dans la thématique ${label}`,
        }))}
      />

      <Footer />
    </>
  )
}

export const query = graphql`
  query GetAllThemes {
    illustration: file(relativePath: { eq: "illustrations/flower.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          width: 1200
          quality: 80
        )
      }
    }
    allThemesTsv(filter: { id: { ne: "divers" } }) {
      edges {
        node {
          id
          label
        }
      }
    }
    allHaikusTsv(filter: { theme: { ne: "divers" } }) {
      group(field: theme) {
        theme: fieldValue
        count: totalCount
      }
    }
    allRedirectionsTsv {
      edges {
        node {
          id
          to
        }
      }
    }
  }
`
